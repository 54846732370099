// PATH SIMPLIFICATION ALGORITHM
// Douglas Peucker path simplification algorithm
// This code is based on https://gist.github.com/adammiller/826148

class Line {
    constructor(p1, p2) {
        this.p1 = p1;
        this.p2 = p2;
        this.distanceToPoint = function (point) {
            // slope
            var m = (this.p2[0] - this.p1[0]) / (this.p2[1] - this.p1[1]),
                // y offset
                b = this.p1[0] - (m * this.p1[1]), d = [];
            // distance to the linear equation
            d.push(Math.abs(point[0] - (m * point[1]) - b) / Math.sqrt(Math.pow(m, 2) + 1));
            // distance to p1
            d.push(Math.sqrt(Math.pow((point[1] - this.p1[1]), 2) + Math.pow((point[0] - this.p1[0]), 2)));
            // distance to p2
            d.push(Math.sqrt(Math.pow((point[1] - this.p2[1]), 2) + Math.pow((point[0] - this.p2[0]), 2)));
            // return the smallest distance
            return d.sort(function (a, b) {
                return (a - b); //causes an array to be sorted numerically and ascending
            })[0];
        };
    }
}

export default {
    data: () => ({
        points: [],
    }),
    
    methods: {

        simplify: (points, tolerance) => {
            let douglasPeucker = function( points, tolerance ) {
                if ( points.length <= 2 ) {
                    return [points[0]];
                }
                let returnPoints = [],
                // make line from start to end
                        line = new Line( points[0], points[points.length - 1] ),
                // find the largest distance from intermediate poitns to this line
                        maxDistance = 0,
                        maxDistanceIndex = 0,
                        p;
                for( let i = 1; i <= points.length - 2; i++ ) {
                    let distance = line.distanceToPoint( points[ i ] );
                    // console.log('distance ' + distance)
                    if( distance > maxDistance ) {
                        maxDistance = distance;
                        maxDistanceIndex = i;
                    }
                }
                // check if the max distance is greater than our tollerance allows
                // console.log('max distance ' + maxDistance)
                if ( maxDistance >= tolerance ) {
                    p = points[maxDistanceIndex];
                    line.distanceToPoint( p, true );
                    // include this point in the output
                    returnPoints = returnPoints.concat( douglasPeucker( points.slice( 0, maxDistanceIndex + 1 ), tolerance ) );
                    // returnPoints.push( points[maxDistanceIndex] );
                    returnPoints = returnPoints.concat( douglasPeucker( points.slice( maxDistanceIndex, points.length ), tolerance ) );
                } else {
                    // ditching this point
                    p = points[maxDistanceIndex];
                    line.distanceToPoint( p, true );
                    returnPoints = [points[0]];
                }
                return returnPoints;
            };
            let result = douglasPeucker( points, tolerance );
            // always have to push the very last point on so it doesn't get left off
            result.push( points[points.length - 1 ] );
            return result;
        },

        // FUNCTIONS
    // makes a deep copy of the array
    cloneCoords(sourceArray) {
        let destArray = [];
        for(let i = 0; i < sourceArray.length; i++) {
            destArray.push([sourceArray[i][0], sourceArray[i][1]]);
        }
        return destArray;
    },

    // Converts [{lat,lng},...,{lat,lng}] to [[0][1],...,[0][1]]
    convertToArrayOfArray(sourceArray) {
        let destArray = [];
        for(let i = 0; i < sourceArray.length; i++) {
            destArray.push([sourceArray[i].lat, sourceArray[i].lng]);
        }
        return destArray;
    },

    formatNumber(number) {
        return Math.round(number * 100)/100;
    },

    // These two functions allow you to express the epsilon factor
    // (max distance to perpendicular line segment of point to remove)
    // in metres.
    // About this value see
    // https://en.wikipedia.org/wiki/Ramer%E2%80%93Douglas%E2%80%93Peucker_algorithm
    degreesToMeters(degrees) { // this is approximate (spherical earth, average circumference, not considering altitude, etc.)
        let earth = 40075000; // average circumference in metres at sea level
        return degrees * earth / 360.0;
    },

    metersToDegrees(meters) { // this is approximate (spherical earth, average circumference, not considering altitude, etc.)
        let earth = 40075000; // average circumference in metres at sea level
        return meters * 360.0 / earth;
    },

    // Apply simplification algorithm using specified tolerance & redraw shape
    // Save points in memory
    simplifyPath(points, tolerance, process) {
        
        let filtered = points.filter(function(point){
            return ((point.lat != null) && (point.lng != null) && (point.lat !== '0.0') && (point.lng !== '0.0'));
        });

        if (process) {
            let toleranceInDegrees = this.metersToDegrees(tolerance)
            return this.simplify(this.convertToArrayOfArray(filtered), toleranceInDegrees)
        } else {
            return filtered
        }
    },

   
},
    
}

