<template>
    <v-dialog
        v-model="settingsDialog"
        @close="closeSettings"
        @click:outside="closeSettings"
        :fullscreen="this.$vuetify.breakpoint.xsOnly"
    >
        <v-card>
            <v-toolbar
                flat
            >
                <v-btn
                    icon
                    @click="closeSettings"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>Settings</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
                <v-list
                    subheader
                >
                    <v-subheader>Order table</v-subheader>
                    <v-divider></v-divider>
                    <v-row justify="space-around">
                        <v-checkbox v-model="header" value="No" label="Number" class="mx-2"></v-checkbox>
                        <v-checkbox v-model="header" value="Map" label="Order map" class="mx-2"></v-checkbox>
                        <v-checkbox v-model="header" value="Order number" label="Order number"
                                    class="mx-2"></v-checkbox>
                        <v-checkbox v-model="header" value="Cargo" label="Cargo" class="mx-2"></v-checkbox>
                        <v-checkbox v-model="header" value="Truck" label="Truck" class="mx-2"></v-checkbox>
                        <v-checkbox v-model="header" value="Semi-trailer" label="Semi-trailer"
                                    class="mx-2"></v-checkbox>
                        <v-checkbox v-model="header" value="Status" label="Status" class="mx-2"></v-checkbox>
                    </v-row>
                    <v-container fluid class="pa-0 ma-0" v-if="this.$vuetify.breakpoint.smAndUp">
                        <v-subheader>Order bar width</v-subheader>
                        <v-divider></v-divider>

                        <v-col cols="12">
                            <v-slider
                                v-model="orderbarmin"
                                thumb-label="always"
                                label="Min bar"
                                step="10"
                                min="300"
                                max="590"
                            ></v-slider>
                        </v-col>
                        <v-col cols="12">
                            <v-slider
                                v-model="orderbarmax"
                                thumb-label="always"
                                label="Max bar"
                                step="10"
                                min="600"
                                max="900"
                            ></v-slider>
                        </v-col>
                    </v-container>
                </v-list>
            </v-card-text>
            <div style="flex: 1 1 auto;"></div>
        </v-card>
    </v-dialog>
</template>

<script>
import {bus} from '../main';

export default {
    name: "Settings",
    props: {
        settingsDialog: Boolean,
    },
    data: () => ({}),
    computed: {
        header: {
            get() {
                return this.$store.getters.settings.header
            },
            set(newValue) {
                this.$store.dispatch('setheaders', newValue)
            }
        },
        orderbarmin: {
            get() {
                return this.$store.getters.settings.orderbarmin
            },
            set(newValue) {
                this.$store.dispatch('setorderbarmin', newValue)
            }
        },
        orderbarmax: {
            get() {
                return this.$store.getters.settings.orderbarmax
            },
            set(newValue) {
                this.$store.dispatch('setorderbarmax', newValue)
            }
        },
    },
    mounted() {
    },
    methods: {
        closeSettings() {
            bus.$emit('settingsDialogClose')
        },
    },
}
</script>
