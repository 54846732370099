<template>
    <v-container fluid class="pa-0 ma-0" grid-list-md>
        <v-data-table
            :headers="customheaders"
            :items="items"
            :expanded.sync="expanded"
            item-key="order_id"
            :fixed-header="true"
            :options.sync="options"
            :loading="loading"
            :dense="dense"
            show-expand
            :disable-pagination="true"
            :hide-default-footer="true"
            class="elevation-1"
            @click:row="clicked"
        >
            <template v-slot:item.ordermap="{ item }">
                <v-btn text @click.stop="mapDialogShow(item.order_id, item.client_order_id, item.direction)">
                    <v-icon color="primary">navigation</v-icon>
                </v-btn>
            </template>
            <template v-slot:item.status="{ item }">
                <v-chip :color="badgeColor(item.status)">
                    {{ matchStatus(item.status) }}
                </v-chip>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
                <!-- <td></td> -->
                <td :colspan="headers.length-1" class="ma-0 pa-0">
                    <dl class="expandinfo pa-3">
                        <dt>Loaded at</dt>
                        <dd>{{ item.first_loading_datetime }}</dd>
                        <dt>Load address</dt>
                        <dd>{{ item.first_loading_place }}</dd>
                        <dt>Delivered at</dt>
                        <dd>{{ item.last_unloading_datetime }}</dd>
                        <dt>Delivery address</dt>
                        <dd>{{ item.last_unloading_place }}</dd>
                    </dl>

                </td>
            </template>
        </v-data-table>
        <order-map :mapDialog="mapDialog" :order_param="order_param" v-if="mapDialog" @close="mapDialogClose"/>
        <v-bottom-sheet v-model="errorSheet" inset hide-overlay>
            <v-sheet class="text-center" height="150px">
                <v-btn
                    class="my-6"
                    depressed
                    color="error"
                    @click="errorClose"
                >close
                </v-btn>
                <div class="title">Network error</div>
                <div>{{ errorMsg }}</div>
            </v-sheet>
        </v-bottom-sheet>
    </v-container>
</template>

<script>
import OrderMap from "../components/OrderMap";
import {bus} from '../main';

export default {
    name: "OrderTable",
    components: {OrderMap},
    data: () => ({
        searchToggle: false,
        mapDialog: false,
        options: {
            page: 1,
            itemsPerPage: 5,
        },
        expanded: [],
        headers: [
            {text: '', value: 'data-table-expand'},
            {text: 'no', value: 'eilnr', align: 'center'},
            {text: 'Map', value: 'ordermap', align: 'center'},
            {text: 'Order number', value: 'client_order_id'},
            {text: 'Cargo', value: 'cargo'},
            {text: 'Truck', value: 'truck.plate_number'},
            {text: 'Semi-trailer', value: 'trailer.plate_number'},
            {text: 'Status', value: 'status'},
        ],
        headers2: [
            {text: 'Loaded at', value: 'first_loading_datetime'},
            {text: 'Load address', value: 'first_loading_place'},
            {text: 'Delivered at', value: 'last_unloading_datetime'},
            {text: 'Delivery address', value: 'last_unloading_place'},
        ],
        headers3: [
            {text: '', value: ''},
            {text: '', value: 'key'},
            {text: '', value: 'value'},
        ],
        dateMenuFrom: false,
        dateMenuTo: false,
        order_param: '',
        errorSheet: false,
        errorMsg: '',
    }),
    computed: {
        loading() {
            return this.$store.getters.loading
        },
        totalItems() {
            return (this.items ? this.items.length : 0)
        },
        items() {
            console.log('order items')
            let items = this.$store.getters.items
            let searchFilter = this.$store.getters.searchFilter
            return this.filterItems(items, searchFilter)
        },
        dense() {
            return this.$store.getters.dense || false
        },
        customheaders() {
            return this.headers.filter(header => {
                return (this.$store.getters.settings.header.indexOf(header.text) !== -1)
            })
        },
    },
    watch: {},

    mounted() {
        bus.$on('mapDialogClose', () => {
            this.mapDialogClose();
        })
        bus.$on('networkError', (errorMsg) => {
            this.errorSheet = true
            this.errorMsg = errorMsg ? errorMsg : ''
        })
    },
    created() {

    },
    beforeDestroy() {
        bus.$off('mapDialogClose')
        bus.$off('networkError')
    },
    methods: {
        badgeColor(status) {
            switch (status) {
                case 1:
                    return 'blue';
                case 2:
                    return 'yellow';
                case 4:
                    return 'red';
                case 5:
                    return 'green';
                default:
                    return 'default';
            }
        },
        matchStatus(status) {
            switch (status) {
                case 1:
                    return 'Planned';
                case 2:
                    return 'In progress';
                case 4:
                    return 'Rejected';
                case 5:
                    return 'Completed';
                default:
                    return 'Unknown';
            }
        },
        clicked(value) {
            let index = this.expanded.indexOf(value);
            if (index === -1) {
                this.expanded.push(value)
            } else {
                this.expanded.splice(index, 1);
            }
        },

        filterItems(items, searchtxt) {
            let self = this
            return items.filter(item => {
                if (item) {
                    return self.filterObject(item, searchtxt)
                } else {
                    return false
                }
            })
        },
        filterObject(item, searchtxt) {
            let self = this
            return Object.keys(item).filter(key => {
                if (item[key] != null) {
                    if (typeof item[key] === 'object') {
                        return self.filterObject(item[key], searchtxt)
                    } else {
                        let value = item[key].toString()
                        return (value.toLowerCase().indexOf(searchtxt.toLowerCase()) !== -1)
                    }
                } else {
                    return false
                }
            }).length
        },
        mapDialogShow(id, client_order_id, direction) {
            this.order_param = {
                order_id: id,
                client_order_id: client_order_id,
                direction: direction
            }
            this.mapDialog = true;
        },
        mapDialogClose() {
            this.mapDialog = false
        },
        errorClose() {
            this.errorSheet = false
            this.mapDialogClose()
        },

    }
}
</script>

<style scoped lang="scss">
    .expandinfo dt {
        font-weight: bold;
    }

    .expandinfo dt::after {
        content: ":";
    }

    .round {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        margin-right: 8px;
    }

</style>