<template>
    <div class="text-center">
        <v-dialog
            v-model="mapDialog"
            @close="closeDialog"
            :fullscreen=true
        >
            <v-card fluid height="80vh">
                <v-toolbar flat dark class="headline" color="primary">
                    <v-btn
                        icon
                        @click="closeDialog"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>
                        Order: {{ order_param.client_order_id }}
                    </v-toolbar-title>
                </v-toolbar>
                <v-container fluid class="pa-0 ma-0">
                    <div id="mapOrder" class="mapOrder"></div>
                        <v-btn
                            v-if="!temperature && isShowTemperature"
                            class="temperature-button"
                            color="accent"
                            medium
                            elevation="3"
                            @click="temperature = true"
                        >
                            Temperature
                        </v-btn>
                </v-container>
            </v-card>
            <v-dialog
                v-model="loadingMap"
                hide-overlay
                persistent
                width="300"
            >
                <v-card
                    color="primary"
                    dark
                >
                    <v-card-text>
                        loading
                        <v-progress-linear
                            indeterminate
                            color="white"
                            class="mb-0"
                        ></v-progress-linear>
                    </v-card-text>
                </v-card>
            </v-dialog>
            <v-dialog
                v-model="noData"
                max-width="300"
            >
                <v-card>
                    <v-card-title>
                        No data for this order
                    </v-card-title>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            text
                            @click="closeDialog"
                        >
                            close
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-dialog>
        <v-dialog
            v-model="temperature"
            content-class="temperature-block"
            hide-overlay
            persistent
            transition="dialog-bottom-transition"
        >
            <v-card fluid>
                <v-toolbar flat dark class="headline" color="primary">
                    <v-btn
                        icon
                        @click="closeTemperatureBlock"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>
                        Temperature
                    </v-toolbar-title>
                </v-toolbar>
                <v-container fluid class="pa-0 ma-0">
                    <line-chart :chart-data="chartData"></line-chart>
                </v-container>
            </v-card>
        </v-dialog>
        <!--        <v-dialog v-model="button" style=" position: absolute;-->
<!--        bottom: 0;-->
<!--        right: 0;-->
<!--        z-index: 0; width: 25px; height: 25px;">-->
<!--            <v-btn small icon>-->
<!--                <v-icon color="primary">settings</v-icon>-->
<!--            </v-btn>-->
<!--        </v-dialog>-->

    </div>
</template>

<script>
    import L from 'leaflet';
    import { bus } from '../main';
    import mixin from "../plugins/pathsimplification.js";
    import LineChart from "./LineChart.js";

    export default {
        name: "OrderMap",
        components: {
            LineChart,
        },
        props: {
            mapDialog: Boolean,
            direction: Number,
            order_param: Object,
        },
        mixins: [mixin],

        data: () => ({
            map: null,
            tileLayer: null,
            processSimplify: true,
            tolerance: 500,
            markersLayer: [],
            temperature: false,
            isShowTemperature: false,
            noData: false,
            chartData: {},
        }),

        computed: {
            orderItem() {
                console.log('get item map');
                return this.$store.getters.orderItem
            },
            loadingMap() {
                return this.$store.getters.loadingMap
            },
        },

        created() {
            bus.$on('loadedOrderFromApi', () => {
                console.log('bus loaded order');
                this.initTemperatureChart()
                this.initMarkers()
            })
            bus.$on('loadedDataFromApi', () => {
                console.log('bus loaded data');
                this.loadOrderFromApi ()
            })
        },

        beforeDestroy () {
            bus.$off('loadedOrderFromApi')
            bus.$off('loadedDataFromApi')
            bus.$off('loadTransportFromApi')
        },

        mounted() {
            this.initMap()
            if (this.orderId != '') {
                console.log('order ref created');
                this.loadOrderFromApi ()
            }
        },

        methods: {
            loadOrderFromApi () {
                this.$store.dispatch('setOrderParam', this.order_param)
                this.$store.dispatch('loadOrderFromApi')
            },
            initTemperatureChart() {
                let orderWayPoints = this.orderItem.completed_waypoints
                if (
                    (orderWayPoints != null)
                    && (orderWayPoints.length > 0)
                    && (this.orderItem.is_show_temperature)
                ) {
                    this.isShowTemperature = this.orderItem.is_show_temperature
                    let temp1 = [];
                    let temp2 = [];
                    let datetime = [];
                    let start = new Date(orderWayPoints[0].datetime).getTime() - 3600000
                    let currentDateTime = null;
                    orderWayPoints.forEach(item => {
                        currentDateTime = new Date(item.datetime).getTime()
                        if (start < currentDateTime && item.temp_1 !== null && item.temp_1 !== "200.0") {
                            datetime.push(item.datetime.slice(0, -3))
                            temp2.push(item.temp_2);
                            temp1.push(Math.round(item.temp_1))
                            start = start + 3600000
                        }
                    })
                    this.chartData = {
                        labels: datetime,
                        datasets: [
                            {
                                data: temp1,
                                label: "Temperature 1",
                                borderColor: "#3e95cd",
                                fill: false,
                            },
                            {
                                data: temp2,
                                label: "Temperature 2",
                                borderColor: "#8e5ea2",
                                fill: false,
                            },
                        ],
                    }
                    this.temperature = true
                }
            },
            initMarkers() {
                console.log('init markers start');
                this.markersLayer.clearLayers();
                this.waypointsLayer.clearLayers();
                this.plannedLayer.clearLayers();

                let locationCoor = [];
                let marker;
                let redIcon = new L.Icon({
                    iconUrl: 'marker-icon.png',
                    iconSize: [16, 16],
                    iconAnchor: [8, 8],
                    popupAnchor: [1, -8],
                    tooltipAnchor: [0, -8],
                    shadowSize: [41, 41],
                    className: 'leaflet-div-icon-red'
                });
                let redIconFill = new L.Icon({
                    iconUrl: 'marker-icon.png',
                    iconSize: [16, 16],
                    iconAnchor: [8, 8],
                    popupAnchor: [1, -8],
                    tooltipAnchor: [0, -8],
                    shadowSize: [41, 41],
                    className: 'leaflet-div-icon-red-fill'
                });

                let greenMarkerIcon = L.divIcon({
                    className: 'custom-div-icon',
                    //html: `<div style='background-color: #176105;' class='marker-pin'></div><i style="color: #302861" class='material-icons'>local_shipping</i>`,
                    html: `<div style='background-color:#176105;' class='marker-pin marker-pin-custom'></div>`,
                    iconSize: [30, 42],
                    iconAnchor: [15, 42],
                    popupAnchor: [1, -34],
                    tooltipAnchor: [1, -34],
                })
                let blueMarkerIcon = L.divIcon({
                    className: 'custom-div-icon',
                    html: `<div style='background-color: #302861;' class='marker-pin marker-pin-custom'></div>`,
                    iconSize: [30, 42],
                    iconAnchor: [15, 42],
                    popupAnchor: [1, -34],
                    tooltipAnchor: [1, -34],
                })

                let trailerMarkerIcon = L.divIcon({
                    className: 'custom-div-icon',
                    html: `<div style='background-color: #302861;' class='marker-pin'></div><i style="color: #302861" class='material-icons'>local_shipping</i>`,
                    iconSize: [30, 42],
                    iconAnchor: [15, 42],
                    popupAnchor: [1, -34],
                    tooltipAnchor: [1, -34],
                })

                let orderItem = this.orderItem
                let lat = 0;
                let lng = 0;
                if ((orderItem.completed_waypoints != null) && (orderItem.completed_waypoints.length > 0)) {
                    let waypoints_simply = this.simplifyPath(
                        orderItem.completed_waypoints,
                        this.tolerance,
                        this.processSimplify
                    )
                    waypoints_simply.forEach(item => {
                        let coord = item
                        locationCoor.push(coord);
                    });
                    let polyline = L.polyline(locationCoor, {
                        color: 'green',
                        noClip:true,
                        weight: 6,
                        opacity: 0.8,
                        lineJoin: 'round',
                        zoomAnimation: false,
                        fadeAnimation: false,
                        markerZoomAnimation: false,
                        zoomAnimationThreshold: false,
                        animate: false,
                    });
                    this.waypointsLayer.addLayer(polyline)
                }
                lat = 0;
                lng = 0;
                // planned_route
                if ((orderItem.planned_route != null) && (orderItem.planned_route.length > 0)) {
                    orderItem.planned_route.forEach(item => {
                        if ((item.lat != null) && (item.lng != null)) {
                            if ((item.lat != lat) || (item.lng != lng)) {
                                lat = item.lat;
                                lng = item.lng;
                                let coord = [item.lat, item.lng]
                                locationCoor.push(coord);
                                marker = L.marker(coord, {
                                    clickable: false,
                                    icon: (item.stopover ? redIconFill : redIcon )
                                })

                                if (item.stopover) {
                                    marker.bindTooltip('stop over', {direction: 'top'})
                                }
                                this.plannedLayer.addLayer(marker);
                            }
                        }
                    });
                }

                // route_points
                if ((orderItem.route_points != null) && (orderItem.route_points.length > 0)) {
                    orderItem.route_points.forEach(item => {
                        if ((item.lat != null) && (item.lng != null)) {
                            let coord = [item.lat, item.lng]
                            let tooltip_text = ( item.action==2 ? 'unloading' : 'loading' ) + '<br>' + item.datetime
                            let popup_text = '<b>' + item.country + '</b><br>' + this.isText(item.company, '') + '<br>' + this.isText(item.cargo, '')
                            locationCoor.push(coord);
                            let popup = L.popup({
                                className: 'orderlistpopup',
                                maxWidth: 200,
                            })
                            .setLatLng(coord)
                            .setContent(popup_text)

                            let tooltip = L.tooltip({
                                maxWidth: 200,
                                permanent: true,
                                direction: 'bottom'
                            })
                            .setContent(tooltip_text);

                            marker = L.marker(coord, {
                                clickable: false,
                                icon: ( item.action==2 ? greenMarkerIcon : blueMarkerIcon )
                            })
                            .bindPopup(popup)
                            .bindTooltip(tooltip)

                            marker.on('mouseover', function () {
                                this.openPopup();
                            });
                            this.markersLayer.addLayer(marker);
                        }
                    });
                }
                // trailer marker
                if (
                    (orderItem.trailer) && (orderItem.trailer.latitude != null)
                    && (orderItem.trailer.longitude != null)
                    && (orderItem.completed_waypoints)
                    && (orderItem.status === 2)
                ) {
                    let coord = [orderItem.completed_waypoints.pop().lat, orderItem.completed_waypoints.pop().lng]
                    locationCoor.push(coord);
                    let sensors_text = ''
                    orderItem.trailer.sensors.forEach(sensor => {
                        if (sensor.type !== 'direction') {
                            sensors_text +=  '<dt>trailer ' + sensor.type + '</dt><dd >' + this.isText(sensor.value, '-') + '</dd>'
                        }
                    });
                    orderItem.truck.sensors.forEach(sensor => {
                        if (sensor.type !== 'direction') {
                            sensors_text +=  '<dt>truck ' + sensor.type + '</dt><dd >' + this.isText(sensor.value, '-') + '</dd>'
                        }
                    });
                    sensors_text = '<dl class="sensors noborder">' + sensors_text + '</dl>'
                    let popup = L.popup({
                        className: 'orderlistpopup',
                        maxWidth: 200,
                    })
                    .setContent(sensors_text)

                    marker = L.marker(coord, {
                        clickable: false,
                        icon: trailerMarkerIcon
                    })
                    .bindPopup(popup)

                    let tooltip_text = this.isText(orderItem.trailer.plate_number, '')
                    if (tooltip_text != '') {
                        let tooltip = L.tooltip({
                            maxWidth: 200,
                            permanent: true,
                            direction: 'bottom'
                        })
                        .setContent(tooltip_text);

                        marker.bindTooltip(tooltip)
                    }

                    marker.on('mouseover', function () {
                        this.openPopup();
                    });
                    this.markersLayer.addLayer(marker);
                }

                if (locationCoor.length) {
                    let bounds = new L.latLngBounds(locationCoor);
                    this.map.fitBounds(bounds, {padding: [50,50]});
                    this.noData = false
                } else {
                    console.log('no markers data')
                    this.noData = true
                }
                this.$store.dispatch('setLoadingMap', false);
            },
            initMap() {
                console.log('init map start');
                this.map = L.map('mapOrder', {
                    inertia: false,
                    zoomAnimation: false,
                    fadeAnimation: false,
                    markerZoomAnimation: false,
                    zoomAnimationThreshold: false,
                    animate: false,
                }).setView([55.719196, 21.293514], 17);

                this.tileLayer = L.tileLayer(
                    'https://cartodb-basemaps-{s}.global.ssl.fastly.net/rastertiles/voyager/{z}/{x}/{y}.png',
                    {
                    attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, &copy; <a href="https://carto.com/attribution">CARTO</a>',
                    maxZoom: 18,
                })
                this.tileLayer.addTo(this.map);
                this.map.zoomControl.setPosition('topleft');
                this.waypointsLayer = new L.LayerGroup({
                    inertia: false,
                    zoomAnimation: false,
                    fadeAnimation: false,
                    markerZoomAnimation: false,
                    zoomAnimationThreshold: false,
                    animate: false,
                    trackResize: false,
                }).addTo(this.map)
                this.plannedLayer = new L.LayerGroup({}).addTo(this.map)
                this.markersLayer = new L.LayerGroup({}).addTo(this.map)
              // this.temperature = new L.LayerGroup({}).addTo(this.map)

                L.control.layers(null, {
                    "Cargo": this.markersLayer,
                    "Completed": this.waypointsLayer,
                    "Planned": this.plannedLayer,
                    // "Temperature" : this.temperature,
                }, {
                    collapsed: false,
                    hideSingleBase: false,
                    position: 'topright',
                }).addTo(this.map);
            },
            closeDialog () {
                bus.$emit('mapDialogClose')
            },
            closeTemperatureBlock () {
                this.temperature = false
            },
            isText(text, def) {
                return ( text != null ? text : def)
            },
        },
    }
</script>

<style lang="scss">
    .mapOrder {
        min-height: calc(100vh - 65px);
        @media only screen and (max-width: 960px) {
        min-height: calc(100vh - 57px);
        }
    transform: none;
    }

    .temperature-block {
        border-radius: 25px;
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: 0;
        max-width: 600px;
        max-height: 600px;
        min-height: 400px;
        background: white;
    }
    .temperature-button {
        position: absolute;
        right: 0;
        bottom: 0;
        z-index: 1000;
        margin: 0px 15px 50px 0px;
    }
    .leaflet-div-icon-red {
        background:none;
        border:5px solid red;
        color:blue;
        font-weight:bold;
        text-align:center;
        border-radius:50%;
        line-height:30px;
    }
    .leaflet-div-icon-red-fill {
        background:red;
        border:5px solid red;
        color:blue;
        font-weight:bold;
        text-align:center;
        border-radius:50%;
        line-height:30px;
    }
    .leaflet-div-icon-green-fill {
        background:green;
        border:5px solid green;
        color:blue;
        font-weight:bold;
        text-align:center;
        border-radius:50%;
        line-height:30px;
    }
    .leaflet-control-layers {
        position: relative;
    }

    .leaflet-pane,
    .leaflet-map-pane {
        transform: none;
    }

    .marker-pin-custom::after {
        content: "";
        width: 12px;
        height: 12px;
        margin: 9px 0 0 9px;
        background: #fff;
        position: absolute;
        border-radius: 50%;
    }

    .orderlistpopup dl {
        border: 1px solid #ccc;
        padding: 3px;
        margin-top: 10px;
    }
    .orderlistpopup dt {
        float: left;
        clear: left;
        min-width: 80px;
        text-align: right;
        font-weight: normal;
    }
    .orderlistpopup dt::after {
        content: ":";
    }
    .orderlistpopup dd {
        margin: 0 0 0 90px;
        padding: 0 0 2px 0;
    }
    .container {
        width: 100% !important;
    }
</style>