<template>
    <v-container fluid class="pa-0 ma-0" >
        <order-filter />
        <order-table />
    </v-container>
</template>

<script>
    import OrderFilter from "../components/OrderFilter";
    import OrderTable from "../components/OrderTable";
    
    export default {
        name: "OrderList",   
        components: {OrderFilter, OrderTable},    
    }
</script>

<style lang="scss">

</style>