<template>
    <v-container fluid class="pa-0 ma-0" >
        <v-container fluid grid-list-md >
            <v-layout row wrap>
                <v-flex class="d-flex" xs="12" sm="12" md="2" lg="2">
                    <v-spacer></v-spacer>

                    <v-btn small fab icon @click="setDense">
                        <v-icon color="primary">line_weight</v-icon>
                    </v-btn>

                    <!-- <v-btn small fab icon @click="settingsToggle = !settingsToggle"> -->
                    <v-btn small fab icon @click.stop="settingsDialogShow()">
                        <v-icon color="primary">settings</v-icon>
                    </v-btn>

                    <v-btn small fab icon  @click="setbarwidth" v-if="this.$vuetify.breakpoint.smAndUp" >
                        <v-icon color="primary">{{ navbaricon() }}</v-icon>
                    </v-btn>
            </v-flex>
            </v-layout>

            <!-- <settings v-show="settingsToggle"/> -->

            <v-layout row wrap>
                <v-flex class="d-flex" xs="12" sm="12" md="6" lg="6">
                    <v-menu
                        v-model="dateMenuFrom"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                v-model="dateFrom"
                                label="from"
                                prepend-icon="event"
                                readonly
                                hide-details
                                v-on="on"
                                style="max-width: 150px;"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="dateFrom"
                            locale="lt"
                            first-day-of-week=1
                            @input="inputDateFrom"
                        ></v-date-picker>
                    </v-menu>
                </v-flex>
                <v-flex class="d-flex" xs="12" sm="12" md="6" lg="6">
                    <v-menu
                        v-model="dateMenuTo"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                v-model="dateTo"
                                label="to"
                                prepend-icon="event"
                                readonly
                                hide-details
                                v-on="on"
                                style="max-width: 150px;"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="dateTo"
                            locale="lt"
                            first-day-of-week=1
                            @input="inputDateTo"
                        ></v-date-picker>
                    </v-menu>
                </v-flex>
            </v-layout>
            <v-layout row wrap>
                <v-flex class="d-flex" xs="12" sm="12" md="12" lg="12">
                    <v-text-field v-model="searchFilter" label="Search" clearable
                        prepend-icon="search" autocomplete="off"
                        >
                    </v-text-field>
                </v-flex>
            </v-layout>
        </v-container>
        <settings :settingsDialog="settingsDialog" v-if="settingsDialog" @close="settingsDialogClose" />
    </v-container>
</template>

<script>
    import Settings from "../components/Settings";
    import { bus } from '../main';

    export default {
        name: "OrderFilter",
        components: {Settings},
        data: () => ({
            menuitems: [],
            searchToggle: false,
            settingsToggle: false,
            settingsDialog: false,
            dateMenuFrom: false,
            dateMenuTo: false,
        }),
        computed: {
            barwidth: {
                get () { return this.$store.getters.barwidth },
                set (newValue) { return this.$store.dispatch('setbarwidth', newValue) }
            },
            searchFilter: {
                get () { return this.$store.getters.searchFilter },
                set (newValue) { return  this.$store.dispatch('setSearchFilter', newValue) }
            },
            dateTo: {
                get () { return this.$store.state.dateTo },
                set (newValue) { return this.$store.dispatch('setdateTo', newValue) }
            },
            dateFrom: {
                get () { return this.$store.state.dateFrom },
                set (newValue) { return this.$store.dispatch('setdateFrom', newValue) }
            },
        },
        mounted() {
            this.searchToggle = this.$store.getters.searchFilter.length
            bus.$on('settingsDialogClose', () => {
                this.settingsDialogClose();
            })
        },
        created() {
            this.menuitems = [
                {title: 'Order list', icon: 'arrow_up', route: 'OrderList'},
                {title: 'Map', icon: 'navigation', route: 'Map'},
            ]
            bus.$on('closeSettings', () => {
                this.settingsToggle = false;
            })
        },
        methods: {
            navigate(name) {
                    this.$router.push({name: name})
            },
            clearFilter () {
                this.searchFilter = ''
                //this.searchFilter = this.$store.getters.searchFilter
            },
            inputDateFrom () {
                this.dateMenuFrom = false
                this.$store.dispatch('loadDataFromApi').then(
                    resolve => {
                        if (resolve.data.transport_list) {
                            this.$store.dispatch('setTransportList', resolve.data.transport_list)
                        }
                        this.$store.dispatch('loadTransportFromApi')
                    }
                )
            },
            inputDateTo () {
                this.dateMenuTo = false
                this.$store.dispatch('loadDataFromApi').then(
                    resolve => {
                        if (resolve.data.transport_list) {
                            this.$store.dispatch('setTransportList', resolve.data.transport_list)
                        }
                        this.$store.dispatch('loadTransportFromApi')
                    }
                )
            },
            setbarwidth () {
                let bw = this.$store.getters.settings.barwidth
                if (bw == 1) {
                    bw = 2
                } else {
                    bw = 1
                }
                this.$store.dispatch('setbarwidth', bw)
            },
            setDense () {
                let dense = !this.$store.getters.dense
                this.$store.dispatch('setdense', dense)

                return dense
            },
            navbaricon() {
                if (this.$store.getters.settings.barwidth == 1) {
                    return 'mdi-arrow-right-bold';
                } else {
                    return 'mdi-arrow-left-bold';
                }
            },
            settingsDialogShow() {
                this.settingsDialog = true
            },
            settingsDialogClose () {
                this.settingsDialog = false
            },
        },
    }
</script>